import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { doCreate } from "../../../api";
import {
  cashRequisitionDefaultData,
  ERRORMESSAGE,
  errorToastOptions,
  successToastOptions,
} from "../../../constants";
import { formatErrorMessage } from "../../../helpers";
import useCashRequisition from "../../../hooks/useCashRequisition";
import Validator from "../../../utils/Validator";
import Input from "../../global/Input";
import InputDate from "../../global/InputDate";
import Textarea from "../../global/Textarea";
import DynamicCashRequisitionForm from "../../global/DynamicCashRequisitionForm";
import { EllipsisLoader } from "../../global/MyLoaders";
interface IAddRequisitionProps {
  onCancel: () => void;
  id: number;
}
const EditCashRequisition = ({ onCancel, id }: IAddRequisitionProps) => {
  const [formLoading, setLoading] = useState(false);
  const [requisition, setRequisition] = useState(cashRequisitionDefaultData);
  const defaultData = [{ name: "", amount: "" }];
  const [values, setValues] = useState<Array<any>>(defaultData);

  const validator = new Validator();
  const { refresh, getCashRequisition, selectedCashRequisition, loading } =
    useCashRequisition();

  useEffect(() => getCashRequisition(id), [id]);

  const formNames = [
    "request_date",
    "amount",
    "reason",
    "delivery_date",
    "description",
  ];

  useEffect(() => {
    let cashRequisitionData = cashRequisitionDefaultData;
    if (!loading) {
      for (let x of formNames) {
        cashRequisitionData = {
          ...cashRequisitionData,
          ...{
            [x]: {
              ...cashRequisitionData[x],
              ...{ error: false, data: selectedCashRequisition[x] },
            },
          },
        };
      }
      setRequisition(cashRequisitionData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  // console.log("selectedCashRequisition", selectedCashRequisition);

  useEffect(() => {
    if (selectedCashRequisition.items) {
      let data = [];
      for (let x of selectedCashRequisition?.items) {
        data.push({
          name: x.name,
          amount: x.amount,
          id: x.id,
        });
      }

      setValues(data);
    }
  }, [selectedCashRequisition]);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(requisition, setRequisition)) {
      setLoading(true);
      const data = {
        amount: requisition.amount.data,
        description: requisition.description.data,
        request_date: requisition.request_date.data,
        delivery_date: requisition.delivery_date.data,
        reason: requisition.amount.data,
        items: values,
      };
      doCreate("cash-requisitions/" + id + "/update", data)
        .then((response) => {
          if (response.data.success === true) {
            toast.success(
              "Your requisition has been successfully Updated",
              successToastOptions
            );
            refresh();
            setTimeout(() => {
              onCancel();
            }, 1000);
          } else {
            toast.error(
              formatErrorMessage(response.data.data, response.data.message),
              errorToastOptions
            );
          }
        })
        .catch(() => {
          toast.error(ERRORMESSAGE, errorToastOptions);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as HTMLInputElement;

    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setRequisition({
        ...requisition,
        ...{
          [name]: { ...requisition[name], ...{ error: false, data: value } },
        },
      });
    } else {
      // indicate an error
      setRequisition({
        ...requisition,
        ...{
          [name]: { ...requisition[name], ...{ error: true, data: value } },
        },
      });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setRequisition({
        ...requisition,
        ...{
          [name]: { ...requisition[name], ...{ error: false, data: value } },
        },
      });
    } else {
      // indicate an error
      setRequisition({
        ...requisition,
        ...{
          [name]: { ...requisition[name], ...{ error: true, data: value } },
        },
      });
    }
  };

  return loading ? (
    <EllipsisLoader />
  ) : (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <InputDate
            name="request_date"
            label="Request Date"
            handleChange={handleSelectChange}
            required
            value={requisition.request_date.data}
            error={requisition.request_date.error}
            errorMessage="Please select the request date"
          />
        </div>
        <div className="col-sm-6">
          <InputDate
            name="delivery_date"
            label="Delivery Date"
            handleChange={handleSelectChange}
            required
            noLimit
            value={requisition.delivery_date.data}
            error={requisition.delivery_date.error}
            errorMessage="Please select the estimated delivery date"
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Input
            label="Total Amount"
            name="amount"
            id="amount"
            handleChange={handleChange}
            required
            type="number"
            value={requisition.amount.data}
            error={requisition.amount.error}
            errorMessage="Please provide the amount"
          />
        </div>
        <div className="col-sm-6">
          <Input
            label="Reason"
            name="reason"
            id="reason"
            handleChange={handleChange}
            required
            value={requisition.reason.data}
            error={requisition.reason.error}
            errorMessage="Please enter the reason fot this requisition"
          />
        </div>
        <div className="col-sm-12">
          <div className="form-row">
            <DynamicCashRequisitionForm
              defaultData={defaultData}
              setValues={setValues}
              values={values}
            />
          </div>
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="reset"
          className="btn btn-outline-danger mr-3"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={formLoading}
          className="btn  btn-info"
          onClick={handleSubmit}
        >
          {formLoading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  );
};

export default EditCashRequisition;
