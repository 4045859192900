import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import useDashboardStats from '../../hooks/useDashboardStats';
import Card from '../../parts/Card'
import { EllipsisLoader } from '../global/MyLoaders';

const RecentActivities = () => {
  const { getRecentActivities, recentActivities, loading } = useDashboardStats();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getRecentActivities(), []);
  return loading ? <EllipsisLoader /> : (
    <div className='col-sm-6'>
      <Card>
        <div>
          <h4>On-going Activities</h4>
          <div className="table-responsive">
            <table className='table table-bordeblue'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Cost</th>
                  <th>Methodology</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {recentActivities?.map(({ id, activity_name, activity_methodology, cost, status }: any) => (
                  <tr key={id}>
                    <td><Link className='text-info' to={`/projects/activities/${id}`} >{activity_name}</Link></td>
                    <td>{Number(cost).toLocaleString()}</td>
                    <td>{activity_methodology}</td>
                    <td>{status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

      </Card>
    </div>
  )
}

export default RecentActivities