import { ChangeEvent, MouseEvent, ReactElement, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { accessTokenKey } from "../../api/helper";
import { login } from "../../api/login";
import { setLoggedUser } from "../../redux/reducer/userReducer";
import { ROUTES } from "../../routes/routes";
import Logo from "../../assets/images/logo.svg";
import Input from "../../components/global/Input";
import toast from "react-hot-toast";
import { ERRORMESSAGE, errorToastOptions } from "../../constants";
import { formatErrorMessage } from "../../helpers";

const Login = (): ReactElement => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const router = useHistory();

  const dispatch = useDispatch();

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'email') {
      setEmail(value);
    }

    if (name === 'password') {
      setPassword(value);
    }
  }

  const handleSubmit = (e: MouseEvent) => {
    e.preventDefault();
    if (email.trim() === "" || password.trim() === '') {
      return;
    }
    setLoading(true);
    login({ email, password }).then((response: any) => {
      if (response.status === 200 && response.data.success === true) {
        dispatch(setLoggedUser(response.data.data));
        sessionStorage.setItem(accessTokenKey, response.data.data.access_token);
        sessionStorage.setItem('user', JSON.stringify(response.data.data));
        setTimeout(() => {
          router.push(ROUTES.DASHBOARD);
        }, 100);
      } else {
        toast.error(formatErrorMessage(null, response.data.message), errorToastOptions);
      }

    }).catch((error) => {
      if (error.response) {
        toast.error(error.response.data.message, errorToastOptions);
      }
      toast.error(ERRORMESSAGE, errorToastOptions);
    }).finally(() => {
      setLoading(false);
    })
  }


  return (
    <form className="overflow-hidden"  >
      <div className="row height-100vh">
        <div className="col-sm-12 col-md-7 bg-info">
          <div className="container">
            <div className="row justify-content-center height-100vh align-items-center no-gutters vertical-align">
              <div className="col px-5 d-flex align-items-center justify-content-center">
                <div className="login-fancy pb-40 clearfix bg-white rounded">
                  <h3 className="mb-30 text-center">Sign In</h3>
                  <div className="section-field mb-20">

                    <Input label="Email" type="email" handleChange={handleTextChange} name="email" value={email} placeholder="Enter your email address" noLabel />
                  </div>
                  <div className="section-field mb-20">
                    <Input label="Password" type="password" handleChange={handleTextChange} name="password" value={password} placeholder="*********************" noLabel />

                  </div>
                  <div className="section-field">
                    <div className="remember-checkbox mb-30">
                      <input type="checkbox" className="form-control" name="two" id="two" />
                      <label htmlFor="two"> Remember me</label>
                      {/* <a href="password-recovery.html" className="float-right">Forgot Password?</a> */}
                    </div>
                  </div>
                  <button type="submit" className="btn btn-info w-100" onClick={handleSubmit} disabled={loading}>
                    {loading ? (
                      <span>Loading...</span>
                    ) : (
                      <>
                        <span>Log in</span>
                        <i className="fa fa-check"></i>
                      </>
                    )}
                  </button>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sm-hidden col-md-5 position-relative" >
          <img src={Logo} style={{ width: '50%', objectFit: 'contain', height: '100%', display: 'flex', margin: '0 auto' }} alt="" />
          <div className="position-absolute d-flex align-items-end justify-content-center" style={{ top: 0, bottom: 0, right: 0, left: 0 }}>
            <div className="p-2">
              <h4 className="text-info">Miles of smiles</h4>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
export default Login;