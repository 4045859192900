import React, { useEffect } from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bubble } from 'react-chartjs-2';
import Card from '../../parts/Card';
import useDashboardStats from '../../hooks/useDashboardStats';
import { EllipsisLoader } from '../global/MyLoaders';

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

export const options = {
  scales: {
    y: {
      beginAtZero: true,
    },
  }, ticks: {

    // forces step size to be 5 units
    stepSize: 1 // <----- This prop sets the stepSize
  }
};



function Dashboard3() {
  const { getPatientAgeGroup, ageGroupStates, loading } = useDashboardStats();

  useEffect(() => {
    return getPatientAgeGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = {
    datasets: [
      {
        label: 'Clients',
        data: ageGroupStates,
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },

    ],
  };

  return (
    <div className='col-sm-12'>
      <Card>
        <div>
          <h4>Clients by age group</h4>
          {loading ? <EllipsisLoader /> : <Bubble options={options} data={data} />}
        </div>
      </Card>
    </div>
  );
}

export default Dashboard3;