import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { doGet } from "../api";
import { RootState } from "../redux/app/store";
import {
  setBudgetComparision,
  setBudgetComparisionDetails,
  setBudgets,
  setSelectedBudget,
} from "../redux/reducer/accounting";

const useBudget = () => {
  const [loading, setLoading] = useState(false);
  const {
    selectedBudget,
    budgets,
    budgetComparision,
    budgetComparisionDetails,
  } = useSelector((state: RootState) => state.accounting);
  const { reportStartDate, reportEndDate } = useSelector(
    (state: RootState) => state.general
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (budgets.length > 0) return;
    setLoading(true);
    doGet("budget")
      .then((response) => {
        dispatch(setBudgets(response.data.data));
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    doGet("budget")
      .then((response) => {
        dispatch(setBudgets(response.data.data));
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const getBudget = (id: number) => {
    setLoading(true);
    doGet("budget/" + id)
      .then((response) => {
        dispatch(setSelectedBudget(response.data.data));
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const getBudgetComparisionDetails = (id: number) => {
    setLoading(true);
    doGet("budget_comparison/" + id)
      .then((response) => {
        dispatch(setBudgetComparisionDetails(response.data.data));
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const getBudgetComparision = () => {
    setLoading(true);
    doGet(
      `budget_comparison?start_date=${reportStartDate}&end_date=${reportEndDate}`
    )
      .then((response) => {
        dispatch(setBudgetComparision(response.data.data));
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loading,
    selectedBudget,
    budgets,
    refresh,
    getBudget,
    getBudgetComparision,
    budgetComparisionDetails,
    getBudgetComparisionDetails,
    budgetComparision,
  };
};

export default useBudget;
