import React, { useEffect } from 'react'
import { TableColumn } from 'react-data-table-component';
import { Link } from 'react-router-dom';
import useReports from '../../hooks/useReports';
import MyDataTable from '../global/MyDataTable';
import { EllipsisLoader } from '../global/MyLoaders';
import GenerateReportsViewHeader from './GenerateReportsViewHeader'

const ConsolidatedMonthlyReport = () => {
  const { loading, getClinicReport, clinicReports } = useReports();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getClinicReport(), []);

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      cell: (row) => <Link className='text-info' to={'reports/clinic/' + row.id}>{row.id}</Link>,
      reorder: true,
      sortable: true,

    }, {
      id: 2,
      name: "Name",
      selector: (row) => row.name,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    }, {
      id: 3,
      name: "Total Patients",
      selector: (row) => row.patients_count,
      reorder: true,
      sortable: true,
      wrap: true,
    }, {
      id: 4,
      name: "Appointments",
      selector: (row) => row.appointments_count,
      reorder: true,
      sortable: true,
      wrap: true,
    }
  ];
  return loading ? <EllipsisLoader /> : (
    <div>
      <div>
        <GenerateReportsViewHeader />
      </div>
      <div>
        {/* data table */}
        <MyDataTable columns={columns} data={clinicReports} title="" />
      </div>
    </div>
  )
}

export default ConsolidatedMonthlyReport