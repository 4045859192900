import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { doGet, getAgeGroupUserData, getAppUserData } from "../api";
import { RootState } from "../redux/app/store";
import { setAgeGroupStates, setAllDashboard, setAppUserStats, setRecentActivities, setRecentAsset, setRecentProject, setRecentStock } from "../redux/reducer/generalReducers";


const useDashboardStats = (): any => {

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    appUserStat, ageGroupStates, recentAssets, recentProjects, recentStock, recentActivities, allDashboard
  } = useSelector(
    (state: RootState) => state.general,
  );


  const getAppUserStats = () => {
    setLoading(true);
    getAppUserData().then((response) => {
      if (response.status === 200) {
        const data = [response.data.data.app_users, response.data.data.other_users];
        dispatch(setAppUserStats(data));
      }
    }).catch(() => {
      dispatch(setAppUserStats([0, 0]))
    }).finally(() => {
      setLoading(false);
    })


  }

  const getAll = () => {
    setLoading(true);
    doGet('dashboard/all').then((response) => {
      if (response.status === 200) {
        const data = response.data.data
        dispatch(setAllDashboard(data));
      }
    }).catch(() => {
      dispatch(setAllDashboard([]));
    }).finally(() => {
      setLoading(false);
    })
  }

  const getPatientAgeGroup = () => {
    setLoading(true);
    getAgeGroupUserData().then((response) => {
      if (response.status === 200) {
        // Array.from({ length: 50 }, () => ({
        //   x: Math.floor(Math.random() * 30) + 20,
        //   y: Math.floor(Math.random() * 100) + 1,
        //   r: Math.floor(Math.random() * 20) + 1,
        // })),
        const res = response.data.data;
        const data = Object.keys(res);
        const statData = [];
        for (let x of data) {
          for (let y of res[x]) {
            const obj = {
              x: Number(x),
              r: Number(x),
              y: y
            }
            statData.push(obj);
          }
        }
        dispatch(setAgeGroupStates(statData));
      }
    }).catch(() => {
      dispatch(setAgeGroupStates([]))
    }).finally(() => {
      setLoading(false);
    })
  }

  const getRecentAsset = () => {
    setLoading(true);
    doGet('dashboard/latest_assets').then((response) => {
      if (response.status === 200) {
        const data = response.data.data
        dispatch(setRecentAsset(data));
      }
    }).catch(() => {
      dispatch(setRecentAsset([]))
    }).finally(() => {
      setLoading(false);
    })


  }

  const getRecentStock = () => {
    setLoading(true);
    doGet('dashboard/latest_stock').then((response) => {
      if (response.status === 200) {
        const data = response.data.data
        dispatch(setRecentStock(data));
      }
    }).catch(() => {
      dispatch(setRecentStock([]))
    }).finally(() => {
      setLoading(false);
    })
  }

  const getRecentProjects = () => {
    setLoading(true);
    doGet('dashboard/latest_projects').then((response) => {
      if (response.status === 200) {
        const data = response.data.data
        dispatch(setRecentProject(data));
      }
    }).catch(() => {
      dispatch(setRecentProject([]))
    }).finally(() => {
      setLoading(false);
    })
  }

  const getRecentActivities = () => {
    setLoading(true);
    doGet('dashboard/latest_activities').then((response) => {
      if (response.status === 200) {
        const data = response.data.data
        dispatch(setRecentActivities(data));
      }
    }).catch(() => {
      dispatch(setRecentActivities([]))
    }).finally(() => {
      setLoading(false);
    })
  }

  return {
    getAppUserStats, loading, appUserStat, getPatientAgeGroup, ageGroupStates, recentAssets, recentProjects, recentStock, getRecentAsset, getRecentStock, getRecentProjects, getRecentActivities, recentActivities, getAll, allDashboard
  };
}

export default useDashboardStats;

