import React, { useEffect, useRef } from "react";
import useBudget from "../../../hooks/useBudget";
import { useReactToPrint } from "react-to-print";
import { EllipsisLoader } from "../../global/MyLoaders";
import { Link } from "react-router-dom";
import useAccounts from "../../../hooks/useAccounts";

const BudgetComparisionReport = () => {
  const { loading, budgetComparision, getBudgetComparision } = useBudget();
  const { reportStartDate, reportEndDate } = useAccounts();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getBudgetComparision(), [reportStartDate, reportEndDate]);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  let creditSum = 0;
  let debitSum = 0;

  return loading ? (
    <EllipsisLoader />
  ) : (
    <>
      <div className="col-xl-12 mb-30">
        <div className="row mx-0 mb-3 justify-content-end">
          <div className="col-xs-12 col-md-3">
            <div className="row justify-content-end">
              <label>Export</label>
              <button
                onClick={handlePrint}
                className="ml-3 btn btn-sm btn-secondary"
              >
                <i className="fa fa-print" />
              </button>
            </div>
          </div>
        </div>
        <table className="table" ref={componentRef}>
          <thead>
            <tr>
              <th className="bg-info text-center text-white py-2" colSpan={5}>
                Budget Comparison Report
              </th>
            </tr>
            <tr>
              <th colSpan={2} className="font-bold">
                Budget Name
              </th>
              <th>Budgeted(shs.)</th>
              <th>Used(shs.)</th>
              <th>Balance(shs.)</th>
            </tr>
          </thead>
          <tbody>
            {budgetComparision?.map((item: any) => (
              <React.Fragment key={item.id}>
                <tr>
                  <td colSpan={2} className="font-medium">
                    <Link to={`reports/budget-comparison/${item.id}`}>
                      {item.budget_name}
                    </Link>
                  </td>
                  <td className="font-medium">
                    {Number(item.budget_amount).toLocaleString()}
                  </td>
                  <td className="font-medium">
                    {Number(item.used_amount).toLocaleString()}
                  </td>
                  <td className="font-medium">
                    {Number(item.remaining_amount).toLocaleString()}
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BudgetComparisionReport;
