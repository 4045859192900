import React from 'react'
import useReportStats from '../../hooks/useReportStats'
import useSessionStorage from '../../hooks/useSessionStorage';
import { EllipsisLoader } from '../global/MyLoaders';
import StatCard from './StatCard'

const DashboardStats = () => {
  const { loading, reportsStats } = useReportStats();
  const { isValidRole } = useSessionStorage();
  return loading ? <EllipsisLoader /> : (
    <div className="row">
      <StatCard title='Clients' icon='fa-wheelchair' value={reportsStats?.patients} iconBg='bg-danger' />
      {isValidRole([1, 3, 4, 6, 9]) && (
        <StatCard title='Total Projects' icon='fa-flag-checkered' value={reportsStats?.projects} iconBg='bg-primary' />

      )}
      <StatCard title='Total Appointments' icon='fa-medkit' value={reportsStats?.appointments} iconBg='bg-warning' />
      <StatCard title='Total Reminders' icon='fa-bell-o' value={reportsStats?.treatments} iconBg='bg-info' />

    </div>
  )
}

export default DashboardStats