import React, { useEffect } from 'react'
import useAnnouncements from '../../hooks/useAnnouncements'
import Card from '../../parts/Card'
import CardHeaderWithBottomBorder from '../global/CardHeaderWithBottomBorder';

const DashboardAnnouncement = () => {
  const { loading, activeAnnouncement, getActive } = useAnnouncements();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getActive(), []);
  return loading ? null : Object.keys(activeAnnouncement).length > 0 ? (
    <Card className='border border-info'>
      <div >
        <CardHeaderWithBottomBorder title='🎤Announcement📢' />
        <h4>{activeAnnouncement?.title} </h4>
        <p>
          {activeAnnouncement?.information}
        </p>

      </div>
    </Card>
  ) : null
}

export default DashboardAnnouncement