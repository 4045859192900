import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import useDashboardStats from '../../hooks/useDashboardStats';
import Card from '../../parts/Card'
import { EllipsisLoader } from '../global/MyLoaders';

const RecentProjects = () => {
  const { getRecentProjects, recentProjects, loading } = useDashboardStats();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getRecentProjects(), []);
  return loading ? <EllipsisLoader /> : (
    <div className='col-sm-6'>
      <Card>
        <div>
          <h4>Recent Projects</h4>
          <div className="table-responsive">
            <table className='table table-bordeblue'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Implementing partner</th>
                  <th>Location</th>
                  <th>Cost</th>
                </tr>
              </thead>
              <tbody>
                {recentProjects?.map(({ id, project_name, implementing_partner, location, cost }: any) => (
                  <tr key={id}>
                    <td><Link className='text-info' to={`/projects/view/${id}`}>{project_name}</Link></td>
                    <td>{implementing_partner}</td>
                    <td>{location}</td>
                    <td>{Number(cost).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default RecentProjects