import React, { useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Card from '../../parts/Card';
import useDashboardStats from '../../hooks/useDashboardStats';
import { EllipsisLoader } from '../global/MyLoaders';

ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard1 = () => {
  const { getAppUserStats, appUserStat, loading } = useDashboardStats();

  useEffect(() => {
    return getAppUserStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = {
    labels: ['Active App users', 'Non App users'],
    datasets: [
      {
        label: '# of total users',
        data: appUserStat,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <div className='col-sm-4'>
      <Card>
        <div>
          <h4>Mobile App Stats </h4>
          {loading ? <EllipsisLoader /> : <Doughnut data={data} />}
        </div>
      </Card>
    </div>
  )
}

export default Dashboard1