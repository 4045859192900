import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import useSessionStorage from '../hooks/useSessionStorage';
import { ROUTES } from '../routes/routes'

const SettingsSideBar = () => {
  const location = useLocation();
  const { isValidRole } = useSessionStorage();

  // const isDev = process.env.NODE_ENV === 'development';
  return (
    <div className='card card-statistics h-100 col-xs-12 col-md-3 px-2 py-3 mb-10'>
      <div className='card-body'>
        <p className='text-muted'>Settings</p>
        <ul className="nav navbar-nav settings_links">
          <li className={`${location.pathname.startsWith(ROUTES.MYPROFILE) && 'active'}`}>
            <NavLink to={ROUTES.MYPROFILE}><i className="fa fa-wrench"></i><span className="ml-2">My profile </span></NavLink>
          </li>
          {isValidRole([1, 4]) && (
            <li className={`${location.pathname.startsWith(ROUTES.USERS) && 'active'}`}>
              <NavLink to={ROUTES.USERS}><i className="fa fa-users"></i><span className="ml-2">Users </span></NavLink>
            </li>
          )}


          {isValidRole([1, 3, 4, 6]) && (
            <li className={`${location.pathname.startsWith(ROUTES.DEPARTMENTS) && 'active'}`}>
              <NavLink to={ROUTES.DEPARTMENTS}><i className="fa fa-building-o"></i><span className="ml-2">Departments </span></NavLink>
            </li>

          )}

        </ul>
        {isValidRole([1, 3, 4, 6, 9]) && (
          <>
            <p className='text-muted'>Project features</p>
            <ul className="nav navbar-nav settings_links">
              <li className={`${location.pathname.startsWith(ROUTES.FUNDERS) && 'active'}`}>
                <NavLink to={ROUTES.FUNDERS}><i className="fa fa-pied-piper-alt"></i><span className="ml-2">Funders </span></NavLink>
              </li>
              <li className={`${location.pathname.startsWith(ROUTES.CATEGORIES) && 'active'}`}>
                <NavLink to={ROUTES.CATEGORIES}><i className="fa fa-cubes"></i><span className="ml-2">Categories </span></NavLink>
              </li>
              <li className={`${location.pathname.startsWith(ROUTES.PROGRAMS) && 'active'}`}>
                <NavLink to={ROUTES.PROGRAMS}><i className="fa fa-sitemap"></i><span className="ml-2">Programs </span></NavLink>
              </li>

            </ul>

            <p className='text-muted'>Activity features</p>

            <ul className="nav navbar-nav settings_links">
              <li className={`${location.pathname.startsWith(ROUTES.ACTIVITYPARAMETERS) && 'active'}`}>
                <NavLink to={ROUTES.ACTIVITYPARAMETERS}><i className="fa fa-linode"></i><span className="ml-2">Parameters </span></NavLink>
              </li>
              <li className={`${location.pathname.startsWith(ROUTES.ACTIVITYTEAMROLES) && 'active'}`}>
                <NavLink to={ROUTES.ACTIVITYTEAMROLES}><i className="fa fa-database"></i><span className="ml-2">Team Roles </span></NavLink>
              </li>
            </ul>


          </>
        )}

        {isValidRole([1, 3]) && (
          <>
            <p className='text-muted'>Asset features</p>
            <ul className="nav navbar-nav settings_links">
              <li className={`${location.pathname.startsWith(ROUTES.ASSETSTYPES) && 'active'}`}>
                <NavLink to={ROUTES.ASSETSTYPES}><i className="fa fa-cubes"></i><span className="ml-2">Categories </span></NavLink>
              </li>
            </ul>
          </>
        )}

        {isValidRole([1, 3, 4, 6]) && (
          <>
            <p className='text-muted'>Accounting</p>
            <ul className="nav navbar-nav settings_links">
              <li className={`${location.pathname.startsWith(ROUTES.PAYMENTTYPES) && 'active'}`}>
                <NavLink to={ROUTES.PAYMENTTYPES}><i className="fa fa-money"></i><span className="ml-2">Payment types </span></NavLink>
              </li>
            </ul>
          </>
        )}

        {isValidRole([1]) && (
          <>
            <p className='text-muted'>Managing committee</p>

            <ul className="nav navbar-nav settings_links">
              <li className={`${location.pathname.startsWith(ROUTES.APPROVINGCOMMITTEE) && 'active'}`}>
                <NavLink to={ROUTES.APPROVINGCOMMITTEE}><i className="fa fa-check-square-o"></i><span className="ml-2">Approving </span></NavLink>
              </li>
              <li className={`${location.pathname.startsWith(ROUTES.REVIEWINGCOMMITTEE) && 'active'}`}>
                <NavLink to={ROUTES.REVIEWINGCOMMITTEE}><i className="fa fa-folder-open-o"></i><span className="ml-2">Reviewing </span></NavLink>
              </li>
              <li className={`${location.pathname.startsWith(ROUTES.UNITS) && 'active'}`}>
                <NavLink to={ROUTES.UNITS}><i className="fa fa-folder-open-o"></i><span className="ml-2">Unit </span></NavLink>
              </li>
            </ul>
          </>
        )}
      </div>
    </div>
  )
}

export default SettingsSideBar