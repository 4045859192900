
import React, { useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import Card from '../../parts/Card';
import useDashboardStats from '../../hooks/useDashboardStats';
import { getData } from '../../utils/helpers';
import { EllipsisLoader } from '../global/MyLoaders';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Graphical representation',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];



export function Dashboard4() {
  const { getAll, allDashboard, loading } = useDashboardStats();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getAll(), []);
  const data = {
    labels,
    datasets: [
      {
        label: 'Appointments',
        data: getData(allDashboard, 'appointments'),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Treatment reminder',
        data: getData(allDashboard, 'reminders'),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
      {
        label: 'Clients',
        data: getData(allDashboard, 'patients'),
        borderColor: 'rgb(53, 235, 174)',
        backgroundColor: 'rgba(53, 235, 129, 0.5)',
      },
    ],
  };
  return (
    <div className='col-sm-8'>
      <Card>
        <div>
          {/* <h4>Accounting</h4> */}

          {loading ? <EllipsisLoader /> : <Line options={options} data={data} />}
        </div>
      </Card>
    </div>
  )

}
