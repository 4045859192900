import React, { useEffect, useRef } from 'react'
import { TableColumn } from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import useReports from '../../hooks/useReports';
import MyDataTable from '../global/MyDataTable';
import { EllipsisLoader } from '../global/MyLoaders';

const ClinicReport = () => {
  const { loading, getClinicReport, clinicReports } = useReports();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getClinicReport(), []);

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      cell: (row) => <Link className='text-info' to={'reports/clinic/' + row.id}>{row.id}</Link>,
      reorder: true,
      sortable: true,
      width: '50px'

    }, {
      id: 2,
      name: "Name",
      cell: (row) => <Link className='text-info' to={'reports/clinic/' + row.id}>{row.name}</Link>,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    }, {
      id: 3,
      name: "Total Patients",
      selector: (row) => row.patients_count,
      reorder: true,
      sortable: true,
      wrap: true,
    }, {
      id: 4,
      name: "Appointments",
      selector: (row) => row.appointments_count,
      reorder: true,
      sortable: true,
      wrap: true,
    }
  ];
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return loading ? <EllipsisLoader /> : (
    <div>
      <div className='row mx-0 mb-3 justify-content-end'>
        <div className='col-xs-12 col-md-3'>
          <div className="row justify-content-end">
            <label>Export</label>
            <button onClick={handlePrint} className="ml-3 btn btn-sm btn-secondary">
              <i className='fa fa-print' />
            </button>
          </div>
        </div>
      </div>
      <div>
        {/* data table */}
        <MyDataTable ref={componentRef} columns={columns} data={clinicReports} title="" />
      </div>
    </div>
  )
}

export default ClinicReport